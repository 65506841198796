import { Link } from 'gatsby';
import React from 'react';

import * as Style from './exampleShops.module.scss';

export const ExampleShops: React.FC = () => {
  const partnerLogos = [
    {
      src: '/partners/logo_partner_asanoya.png',
      alt: 'asanoyaのロゴ',
    },
    {
      src: '/partners/logo_partner_ANDERSEN.png',
      alt: 'ANDERSENのロゴ',
    },
    {
      src: '/partners/logo_partner_VIE-DE-FRANCE.png',
      alt: 'VIE-DE-FRANCEのロゴ',
    },
    {
      src: '/partners/logo_partner_oreno-bakery.png',
      alt: 'oreno-bakeryのロゴ',
    },
    {
      src: '/partners/logo_partner_kamogawa-bakery.png',
      alt: 'kamogawa-bakeryのロゴ',
    },
    {
      src: '/partners/logo_partner_kobeya.png',
      alt: 'kobeyaのロゴ',
    },
    {
      src: '/partners/logo_partner_kobeya-kitchen.png',
      alt: 'kobeya-kitchenのロゴ',
    },
    {
      src: '/partners/logo_partner_SANTA-cafe.png',
      alt: 'SANTA-cafeのロゴ',
    },
    {
      src: '/partners/logo_partner_dans-marche.png',
      alt: 'dans-marcheのロゴ',
    },
    {
      src: '/partners/logo_partner_Deli-france.png',
      alt: 'Deli-franceのロゴ',
    },
    {
      src: '/partners/logo_partner_boulange-okuda.png',
      alt: 'boulange-okudaのロゴ',
    },
    {
      src: '/partners/logo_partner_freds.png',
      alt: 'fredsのロゴ',
    },
    {
      src: '/partners/logo_partner_belle-bois.png',
      alt: 'belle-boisのロゴ',
    },
    {
      src: '/partners/logo_partner_PAUL.png',
      alt: 'PAULのロゴ',
    },
    {
      src: '/partners/logo_partner_bostonbake.png',
      alt: 'bostonbakeのロゴ',
    },
    {
      src: '/partners/logo_partner_maison-kayser.png',
      alt: 'maison-kayserのロゴ',
    },
    {
      src: '/partners/logo_partner_LE-CROISSANT.png',
      alt: 'LE-CROISSANTのロゴ',
    },
    {
      src: '/partners/logo_partner_Lerepas.png',
      alt: 'Lerepasのロゴ',
    },
    {
      src: '/partners/logo_partner_ST.MARC_CAFE.png',
      alt: 'saintmarccafeのロゴ',
    },
  ];
  const shops = partnerLogos.map((logo) => (
    <img key={logo.alt} className={Style.shop} src={logo.src} alt={logo.alt} />
  ));

  return (
    <>
      <div className={Style.exampleShops}>
        <h3>いろいろなお店で使えます</h3>
        <div className={Style.shops}>
          {shops}
          <p className={Style.andMore}> AND MORE</p>
        </div>
      </div>
      <div className={Style.backToSiteTop}>
        <Link to="https://bakery-gift.com/" target="_blank">
          ＜ 全国パン共通券サイトトップへ戻る
        </Link>
      </div>
    </>
  );
};
