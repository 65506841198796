import React from 'react';
import { Button } from 'components/button/button';
import { ButtonGroup } from 'components/buttonGroup/buttonGroup';
import { Header } from 'components/header/header';
import { Footer } from 'components/footer/footer';
import { Layout } from 'components/layout/layout';
import { Seo } from 'components/seo';
import { ExampleShops } from 'components/exampleShops/exampleShops';

import 'styles/default.scss';

const IndexPage = () => (
  <Layout>
    <Seo title="使えるお店を探す" />
    <Header title="使えるお店を探す" />
    <main>
      <ButtonGroup>
        <Button to="/map?useGPS=true">現在地から探す</Button>
        <Button to="/prefacture">都道府県から探す</Button>
      </ButtonGroup>
      <ExampleShops />
    </main>
    <Footer />
  </Layout>
);

export default IndexPage;
