import React from 'react';
import { Link } from 'gatsby';

import * as Style from './button.module.scss';

export interface ButtonProps {
  onClick?: (event: React.MouseEvent<HTMLAnchorElement>) => void;
  to: string;
}

export const Button: React.FC<ButtonProps> = ({ to, onClick, children }) => (
  <Link className={Style.button} to={to} onClick={onClick}>
    {children} <span className={Style.chevronRight} />
  </Link>
);
